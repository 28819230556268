// src/i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';  // Detects user's language

import translationEN from './locales/en/translation.json';
import translationDE from './locales/de/translation.json';

// Configure i18n
i18n
  .use(LanguageDetector)  // Automatically detects the user's language
  .use(initReactI18next)   // Connects i18n with React
  .init({
    resources: {
      en: {
        translation: translationEN,
      },
      de: {
        translation: translationDE,
      },
    },
    fallbackLng: 'en',      // Default language if detection fails
    debug: true,
    interpolation: {
      escapeValue: false,    // React already does escaping
    },
  });

export default i18n;
