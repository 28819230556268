import React from 'react';
import { useTranslation } from 'react-i18next';
import './Team.css';

// Import images directly
import ArvindImg from '../images/Arvind.jpg';
import NishaImg from '../images/nisha.jpg';
import NareshImg from '../images/naresh.jpg';
import DanielImg from '../images/daniel.jpg';
import RakeshImg from '../images/rakesh.jpg';

const Team = () => {
  const { t } = useTranslation();

  return (
    <div className="team">
      <h1>{t('ourTeam')}</h1>
      <p>{t('teamDescription')}</p>

      <div className="team-container">
        <div className="team-member">
          <img src={ArvindImg} alt="Arvind Verma" className="team-avatar" />
          <h3 className="team-title">Arvind Verma</h3>
          <p className="team-description">{t('arvindDescription')}</p>
        </div>
        <div className="team-member">
          <img src={NishaImg} alt="Nisha Verma" className="team-avatar" />
          <h3 className="team-title">Nisha Verma</h3>
          <p className="team-description">{t('nishaDescription')}</p>
        </div>
        <div className="team-member">
          <img src={NareshImg} alt="Naresh A" className="team-avatar" />
          <h3 className="team-title">Naresh A</h3>
          <p className="team-description">{t('nareshDescription')}</p>
        </div>
        <div className="team-member">
          <img src={DanielImg} alt="Daniel D" className="team-avatar" />
          <h3 className="team-title">Daniel D</h3>
          <p className="team-description">{t('danielDescription')}</p>
        </div>
        <div className="team-member">
          <img src={RakeshImg} alt="Rakesh P" className="team-avatar" />
          <h3 className="team-title">Rakesh P</h3>
          <p className="team-description">{t('rakeshDescription')}</p>
        </div>
      </div>
    </div>
  );
};

export default Team;
