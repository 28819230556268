import React from 'react';
import { useTranslation } from 'react-i18next';  // Import useTranslation hook
import './Services.css';

const Services = () => {
  const { t } = useTranslation();  // Use the translation function

  return (
    <div className="services">
      <h1>{t('ourServices')}</h1>  {/* Translation for 'Our Services' */}
      <div className="service-items">
        <div className="service-item">
          <h3>{t('aiConsulting')}</h3>  {/* Translation for 'AI Consulting' */}
          <p>{t('aiConsultingDescription')}</p>  {/* Translation for AI Consulting description */}
        </div>
        <div className="service-item">
          <h3>{t('customAISolutions')}</h3>  {/* Translation for 'Custom AI Solutions' */}
          <p>{t('customAISolutionsDescription')}</p>  {/* Translation for Custom AI Solutions description */}
        </div>
        <div className="service-item">
          <h3>{t('AIDigitalproduct')}</h3>  {/* Translation for 'Global Reach' */}
          <p>{t('globalReachDescription')}</p>  {/* Translation for Global Reach description */}
        </div>
      </div>
    </div>
  );
};

export default Services;
