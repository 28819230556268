import React from 'react';
import { useTranslation } from 'react-i18next';  // Import useTranslation hook
import './About.css';
import ArvindImg from '../images/artificial-intelligence-ai-in-aviation.jpeg';


const About = () => {
  const { t } = useTranslation();  // Get the translation function
  return (
    <div>
      <h1>{t('about')}</h1>
      <p>{t('about_text')}</p>
      <img src={ArvindImg} alt="Arvind Verma" className="team-avatar1" />
      <p>{t('core_competencies')}</p>
    </div>
  );
};

export default About;

