import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';  // Import useTranslation hook
import emailjs from 'emailjs-com';
import './Contact.css';

const Contact = () => {
  const [emailSent, setEmailSent] = useState(false);
  const { t } = useTranslation();  // Use the translation function

  const sendEmail = (e) => {
    e.preventDefault();
    emailjs
      .sendForm('service_90l4vik', 'template_czkernq', e.target, 'info@avermass.de')
      .then(
        (result) => {
          setEmailSent(true);
        },
        (error) => {
          console.error(error.text);
        }
      );
    e.target.reset();
  };

  return (
    <div className="contact">
      <h1>{t('contactUs')}</h1>  {/* Translation for 'Contact Us' */}
      <div className="contact-container">
        <div className="contact-info">
          <p>{t('contactMessage')}</p>  {/* Translation for the message */}
          <p>{t('phone')}: +49 15213500281</p>  {/* Translation for 'Phone' */}
          <p>{t('address')}: Andreas Kasperbauer Straße 10, 85540 Haar, Munich , Germany</p>  {/* Translation for 'Address' */}
        </div>

        <form className="contact-form" onSubmit={sendEmail}>
          <div className="form-group">
            <label>{t('name')}</label>  {/* Translation for 'Name' */}
            <input type="text" name="name" required />
          </div>
          <div className="form-group">
            <label>{t('email')}</label>  {/* Translation for 'Email' */}
            <input type="email" name="email" required />
          </div>
          <div className="form-group">
            <label>{t('message')}</label>  {/* Translation for 'Message' */}
            <textarea name="message" required></textarea>
          </div>
          <button type="submit" className="btn-submit">{t('sendMessage')}</button>  {/* Translation for 'Send Message' */}
        </form>
      </div>
      {emailSent && <p className="success-msg">{t('successMessage')}</p>}  {/* Translation for success message */}
    </div>
  );
};

export default Contact;
